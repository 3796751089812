.user-modal {
  .modalTitle {
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #22294e;
    text-transform: capitalize;
  }
  .close {
    display: none;
  }
  .modal-body {
    padding: 24px;
  }
  .fill-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
    margin-bottom: 24px;
  }
  .modal-footer {
    margin-top: 60px;
    border-top: 1px solid #f8e6df;
  }
  .cancel-button {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #9c9ca3 !important;
    margin-right: 15px !important;
    letter-spacing: 0.5px;
    cursor: pointer;
  }
  .cr-textinput {
    margin-bottom: 16px;
  }
  .text-danger {
    display: flex;
    margin-top: 8px;
    align-items: center;
    svg {
      margin-right: 4px;
    }
    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #141414;
    }
  }
  .cr-textinput label {
    font-size: 12px !important;
    //color: #22294E;
  }
  .invalid {
    color: #f55b5b;
  }
  label {
    font-size: 12px !important;
    color: #22294e;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
  }

  .btn-primary {
    padding: 9px 28.5px !important;
    min-width: 160px;
    height: 60px !important;
  }
  .red {
    color: #f55b5b;
    margin-left: 3px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  .react-select__value-container {
    padding-left: 0px !important;
  }

  .modal-footer {
    margin-bottom: -24px;
    margin-left: -24px;
    margin-right: -24px;
  }
}
.modalTitle {
  font-weight: normal;
  font-size: 20px;
  line-height: 28px !important;
  color: #22294E;
  text-transform: capitalize;
}

.input-number {
  input {
    padding: 0.375rem 0;
    border-width: 0px;
    box-shadow: none !important;
    border-radius: 0;
    border-bottom: 1px solid #22294e !important;
    color: #141414;
    height: 40px;
    outline: none !important;
    margin-bottom: 8px;
  }
  &.error-text {
    label {
      color: #f55b5b;
    }
    input {
      border-bottom: 1px solid #ea734f !important;
    }
  }
  label {
    span {
    }
    font-size: 12px !important;
    color: #22294e;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    margin: 0px;
  }
  svg {
    margin-right: 4px;
  }
  .show-error {
    display: flex;
    align-items: center;
    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #141414;

      font-family: Muli;
      font-style: normal;
    }
  }
}

.modalHeader {
  padding: 20px 24px !important;
  border-bottom: 1px solid #f8e6df;
}

.closeButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;

  &:hover {
    // background: #22294E1A;
    background: none !important;
  }

  &:active {
    background: none !important;
    img {
      filter: brightness(1) invert(1);
    }
    outline: none;
    box-shadow: none;
  }
}
