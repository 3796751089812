.root {
  :global {
    .ck-editor__editable {
      min-height: 200px;
      max-height: 600px;
    }

    .ck.ck-balloon-panel {
      z-index: 2000;
    }
  }

  &.error {
    :global {
      .ck-editor {
        border: 1px solid #f55b5b !important;
      }
    }
  }
}

:global {
  .ck.ck-balloon-panel {
    z-index: 2000;
  }
}
