.root {
  .toText {
    max-width: 20px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items:flex-start;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
  }
}
