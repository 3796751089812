.root {
  display: flex;
  align-items: center;
  .calendarButton {
    font-size: 16px;
    color: #ea734f !important;
    text-align: left;
    background: none !important;
    border: none;
    padding: 0px !important;
    justify-content: flex-start;
    outline: none !important;
    box-shadow: none !important;
    &:hover,
    &:active,
    &:visited {
      text-decoration: none;
    }
  }
}
.modal {
  .scheduleButton {
    button {
      width: 160px !important;
      display: block !important;
    }
  }
  .backButton {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    font-weight: 600;
    text-transform: capitalize;
    color: #515254 !important;
    background: none !important;
    border: none;
    padding: 0px !important;
    &:hover {
      color: #f5b399 !important;
      text-decoration: none;
    }
  }
  .draftButton {
    font-size: 16px;
    color: #ea734f !important;
    background: none !important;
    border: none;
    padding: 0px !important;
    &:hover {
      text-decoration: none;
    }
  }
  .footer {
    padding: 5px 24px;
    box-shadow: 0px -1.5px 4px rgba(20, 20, 20, 0.2);
    @media (max-width: 425.98px) {
      justify-content: center;
    }
  }
  .body {
    padding: 0px;
  }
}
:global {
  .reviewCalendarModal {
    max-width: 1130px !important;
    .modal-content {
      filter: drop-shadow(0px 6px 14px rgba(35, 36, 41, 0.2));
      border-radius: 20px;
      padding: 0px !important;
      // margin: 10px;
    }
  }
}
