.root {
  .classCapacity {
    display: flex;
    .appendText {
      font-size: 14px;
      color: #22294e;
    }
  }

  .inputCapacity {
    input {
      text-align: right;
      width: 40px;
    }
  }

  .save {
    &:hover {
      background: #f5b399;
      opacity: 1;
    }
  }

  .skipButton {
    font-size: 16px;
    color: #9c9ca3 !important;
    background: none !important;
    border: none;
    padding: 0px !important;

    &:hover {
      color: #f5b399 !important;
      text-decoration: none;
    }
  }
}
