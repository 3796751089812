.root {
  .noteText {
    display: flex;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #22294e;
    margin-bottom: 16px;

    .recitalNname {
      text-transform: capitalize;
      margin-left: 5px;
      margin-right: 5px;
    }

    img {
      margin-right: 8px;
    }
  }

  .video {
    img {
      width: 100%;
    }
    margin-bottom: 16px;
  }

  .guide {
    padding: 16px;
    background: #fcfcfc80;
    font-family: Poppins;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: #22294e;

    ol {
      padding-left: 25px;
      li {
        margin-top: 10px;
      }
    }

    .start {
      background: #7bdbc4;
      border-radius: 4px;
      color: white;
      padding: 3px 8px;
      border: none;
    }

    .end {
      background: #f55b5b;
      border-radius: 4px;
      color: white;
      padding: 3px 8px;
      border: none;
    }

    .sendNotify {
      background: #ea9277;
      border-radius: 4px;
      color: white;
      padding: 3px 8px;
      border: none;
    }
  }

  .goLive {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #515b7b;
    margin-bottom: 26px;
    text-align: right;

    @media (min-width: 1199.98px) {
      margin-top: -100px;
    }

    button {
      margin-top: 10px;
      border: none;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
      background: #6dcbff;
      border-radius: 5px;
      box-shadow: none;
      outline: none;

      img {
        margin-right: 0px;
      }
    }
  }

  .sendNofitication {
    width: 100%;

    .arrangeBt {
      text-align: right;
      button {
        background: #ea734f;
        color: white;
        border-radius: 100px;
        padding: 8px 16px;
        outline: none;
        box-shadow: none;
        border: none;
        margin-bottom: 24px;
        img {
          width: 12px;
          height: 12px;
          margin-right: 8px;
        }

        &.arrange {
          background: #515b7b;
        }
      }
    }

    .container {
      padding-bottom: 26px;

      .header {
        display: flex;
        justify-content: space-between;
        letter-spacing: 5px;
        text-transform: uppercase;
        padding: 2px 5px;
        background: #fcf7f4;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #22294e;
        &.perfomning {
          color: #f55b5b;
        }

        span {
          letter-spacing: 0.5px;
        }

        img {
          cursor: pointer;
          margin-right: 5px;
        }

        &.viewer {
          border-radius: 8px;
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
          padding-left: 10px;
        }
      }

      .performance {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #141414;
        margin-top: 24px;
        .content {
          display: flex;
          align-items: center;

          img.drag {
            margin-right: 8px;
            &.hide {
              display: none;
            }
          }

          img.avatar {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            border: 0.5px solid #e0e0e0;
            border-radius: 50%;
          }

          span {
            font-weight: normal;
            margin-left: 5px;
          }

          &.viewer {
            justify-content: space-between;
            align-items: baseline;
            width: 100%;
            font-weight: normal;

            .namePerformance {
              cursor: pointer;

              &.active {
                font-weight: 500;
                color: #51bcda;
              }
            }

            ul {
              list-style: none;
              padding-left: 37px;
              font-weight: normal;
              li + li {
                margin-top: 6px;
              }
            }
          }
        }

        button {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          background: #ea9277;
          color: white;
          border-radius: 4px;
          padding: 8px 16px;
          box-shadow: none;
          outline: none;
          border: none;
          position: relative;

          &:disabled {
            background: #e0e0e0;
            color: #ffffff;
          }

          .tooltip {
            display: none;
            background: #515254;
            border-radius: 5px;
            padding: 4px 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            color: #ffffff;
            position: absolute;
            top: -70px;
            left: 0;
            width: 180px;
            text-align: left;
          }

          &:hover {
            .tooltip {
              display: block;
            }
          }
        }
      }

      .startButton {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        background: #7bdbc4 !important;
        border-radius: 4px;
        color: white;
        border: none;
        box-shadow: none;
        outline: none;
        padding: 8px 16px;

        &:disabled {
          background: #e0e0e0 !important;
        }
      }

      .endButton {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        background: #f55b5b !important;
        border-radius: 4px;
        color: white;
        border: none;
        box-shadow: none;
        outline: none;
        padding: 8px 16px;
        margin-left: 20px;
      }

      .LiveIcon {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #f55b5b;

        img {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
      }

      span {
        font-weight: normal;
      }
    }
  }

  &.dark {

    .noteText {
      color: white;
    }

    .goLive {
      color: white;
    }

    .guide {
      color: white;
      background: none;
    }
    .sendNofitication {
      .arrangeBt {
        button {
          background: #51bcda;
        }
      }

      .container {
        .header {
          background: #32315a;
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
          color: #ffffff;
          border-radius: 8px;
          padding-left: 10px;
          &.perfomning {
            color: #51bcda;
          }

          button {
            background: #51bcda;
          }

          img {
            filter: brightness(0) invert(1);
          }

          &.viewer {
            border-radius: 8px;
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
            padding-left: 10px;
          }
        }
      }

      .performance {
        color: white;
        button {
          background: #51bcda;
        }

        .content {
          img.drag {
            filter: invert(81%) sepia(1%) saturate(0%) hue-rotate(186deg) brightness(101%) contrast(85%);
          }
        }
      }
      .startButton {
        background: #51bcda !important;

        &:disabled {
          background: #e0e0e0 !important;
        }
      }
    }
  }
}
