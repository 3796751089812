.root {
  font-family: Muli;
  line-height: 20px;
  font-style: normal;
  font-weight: 600;
  color: #141414;
  margin-left: -1rem;
  .skipButton {
    font-size: 16px;
    color: #9c9ca3 !important;
    background: none !important;
    border: none;
    padding: 0px !important;

    &:hover {
      color: #f5b399 !important;
      text-decoration: none;
    }
  }

  .calendarButton {
    font-size: 16px;
    color: #ea734f !important;
    text-align: left;
    background: none !important;
    border: none;
    padding: 0px !important;
    justify-content: flex-start;
    outline: none !important;
    box-shadow: none !important;

    &:hover,
    &:active,
    &:visited {
      text-decoration: none;
    }
  }

  .scheduleButton {
    button {
      width: 160px !important;
      display: block !important;
    }
  }

  .content {
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;

    @media (max-width: 375.98px) {
      margin: 0px;
    }
  }
  .toggleSwitch {
    padding-bottom: 5px;
  }
  .titleArea {
    font-weight: 600;
    font-size: 16px;
    color: #22294e;
    display: block;
    line-height: 22px;

    &::after {
      margin-top: 5px;
      content: ' ';
      border-bottom: 1px solid #f8e6df;
      display: block;
    }
  }
  .addButton {
    padding: 0.34375rem 1.25rem;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.3125rem;
    margin-top: 1rem;
    border: 1px solid var(--primary-carrot-ea-734-f, #EA734F);
  }

  .removeButton {
    width: 0rem;
    height: 0rem;
    flex-shrink: 0;
    min-width: 0rem
  }
  .dateTime {
    display: flex;
    font-size: 14px;
    align-items: baseline;
    flex-wrap: wrap;
    font-weight: 600;
    line-height: 20px;

    :global {
      .react-date-picker__inputGroup {
        margin-right: 0px;
        width: auto;

      }
      .react-date-picker__wrapper {
        width: 6.5rem;
        padding: 9px 3px;
        height: 2.5rem;
        flex-shrink: 0;
        border-radius: 0.3125rem;
        border: 1px solid var(--primary-champagne-f-8-e-6-df, #F8E6DF);
        background: var(--tertiary-white-ffffff, #FFF);
      }
    }

    .input {
      box-sizing: border-box;
      background: #ffffff;
      width: 6.875rem;
      height: 2.5rem;
      flex-shrink: 0;
      border-radius: 0.3125rem;
      border: 1px solid var(--primary-champagne-f-8-e-6-df, #F8E6DF);
      background: var(--tertiary-white-ffffff, #FFF);

      &.error {
        background: #fcf7f4;
        border: 1px solid #f55b5b;
      }
    }


    .toggleText {
      font-family: Muli;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #141414;
    }

    .endDateTime {
      display: flex;
      position: relative;
      flex-wrap: wrap;
      align-items: center;
    }


    .toggleSwitch {
      // transform: translateY(-5px);

      @media (max-width: 425.98px) {
        margin-top: 10px;
      }
    }
  }

  .recurring {
    margin-top: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 425.98px) {
      > * {
        margin-bottom: 10px;
      }
    }

    .recurringType {
      width: 140px;
    }

    .recurringNumber {
      width: 33px;
    }
  }

  .repeatOn {
    margin-top: 20px;
    font-size: 14px;

    .activeDay {
      width: 27px;
      height: 27px;
      background: #e0e0e0;
      border-radius: 50%;
      font-weight: 600;
      font-size: 14px;
      color: #22294e;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      padding: 0px;
      margin-bottom: 10px;
      margin-right: 15px;

      &:last-of-type {
        margin-right: 0px;
      }

      @media (max-width: 375.98px) {
        width: 22px;
        height: 22px;
        font-size: 11px;
      }

      &:focus {
        border: none;
        outline: none;
      }

      &.active {
        background: #ea9277;
        color: white;
      }
    }

    .dropdownMonth {
      width: 220px;
    }
  }

  .repeatHours {
    margin-top: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;

    .shortDay {
      width: 50px;
      font-weight: bold;
    }
  }

  .alert {
    display: flex;
    align-items: center;
    :global {
      .alert {
        margin-bottom: 0;
      }
    }

    &.errorTime {
      :global {
        .alert {
          margin-left: 0px !important;
        }
      }
    }

    &.errorDate {
      :global {
        .alert {
          margin-left: 90px;
        }
      }
    }
  }

  :global {
    .cr-alert {
      box-shadow: none !important;
      padding-left: 0px;
    }
  }
}
