.root {
  width: 100%;
  display: contents;
  font-family: Muli;
  position: relative;
  color: #141414;

  .error {
    border-color: #ea734f !important;
    background: #fcf7f4 !important;
  }

  &.hasError {
    :global {
      .react-base-select__indicators {
        display: none;
      }

      .react-base-select__control {
        border-color: #ea734f;
        background: #fcf7f4;
      }
    }
  }

  &.highLight {
    :global {
      .react-base-select__control {
        border-color: #ea734f;
        background: #fcf7f4;
      }
    }
  }
  .noData {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
    text-align: left;

    button {
      color: #ea9277;
      padding: 0;
      margin: 0;
      outline: none;
      box-shadow: none;
      background: none;
      border: none;
      text-transform: capitalize;
    }
  }

  .errorIcon {
    position: absolute;
    bottom: 32px;
    right: 25px;
  }

  .errorMessage {
    align-items: center;
    display: flex;
    font-size: 12px;
    font-family: Muli;
    line-height: 16px;
    margin-top: 5px;
    color: #f55b5b;

    img {
      margin-right: 5px;
    }
  }

  :global {
    .react-base-select__control {
      height: 40px;
      margin-top: 8px;
      border: 1px solid #f8e6df;
      box-shadow: none !important;
      outline: none !important;
      border-radius: 5px;
      font-size: 14px;

      &:hover {
        border-color: #f8e6df;
      }

      &.react-base-select__control--is-focused {
        border-color: #ea734f;
      }

      .react-base-select__indicator-separator {
        display: none;
      }

      .react-base-select__placeholder {
        color: #9c9ca3;
        line-height: 20px;
      }
    }

    .react-base-select__menu {
      max-height: 190px;
      overflow-y: auto;
      // min-width: 213px !important;
      box-shadow: 0px 2px 3px rgba(76, 73, 105, 0.3);
      border-radius: 5px;
      width: 100% !important;
      z-index: 99 !important;
      margin: 0px;
      color: #141414;



      .react-base-select__option--is-selected {
        background: none;
        border-radius: 5px;
        color: black;
      }
      .react-base-select__option--is-focused {
        background: #ea9277 !important;
        color: white;
      }
    }

    .react-base-select__menu-list {
      max-height: 190px;
      overflow-y: auto;
      // padding: 5px;
    }

    .react-base-select__single-value {
      color: #141414;
    }

    .react-base-select__option {
      cursor: pointer;
      padding: 8px !important;
      font-family: Muli;
      font-size: 14px;
      line-height: 20px !important;
      color: #141414;

      &:active {
        background: #ea9277 !important;
        border-radius: 5px;
        color: white;
        font-weight: 600;
      }
    }
  }
}
