.root {
  .icon {
    width: 18px;
  }
  .location {
    width: 100%;
    input {
      width: 100%;
      background: #ffffff;
      border: 1px solid #f8e6df;
      box-sizing: border-box;
      border-radius: 5px;
      height: 40px;
      padding: 10px 8px;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #141414;
      &:focus {
        outline: none;
        border: 1px solid #f55b5b !important;
      }
      &::placeholder {
        font-weight: 600;
        font-size: 14px;
        color: #9c9ca3;
      }
    }

    ul {
      background: #ffffff;
      box-shadow: 0px 2px 3px rgba(76, 73, 105, 0.3);
      border-radius: 0px 0px 5px 5px;
      margin-top: 2px;
      min-width: 400px;
      width: unset;
      padding-left: 0px;
      list-style-type: none;
      display: inline-block;
      position: absolute;
      left: 60px;
      top: 42px;
      z-index: 99999;
      li {
        padding: 8px;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #141414;
        cursor: pointer;
        padding-right: 20px;
        &:hover {
          color: #ea9277;
          // font-weight: 600;
        }
      }
    }
  }
  .SeasonBox {
    min-width: 297px;
  }
  .note {
    width: 100%;
    border: 1px solid #f8e6df;
    box-sizing: border-box;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: none;
    min-height: 100px;

    &::placeholder {
      color: #9c9ca3 !important;
      opacity: 1;
    }

    &:focus {
      box-shadow: none !important;
      outline: none;
      border: 1px solid #f8e6df;
    }
  }

  .room {
    width: 50%;
    &.react-select__menu {
      width: 100%;
    }

    @media (max-width: 425.98px) {
      width: 80%;
    }
  }

  .warningMessage {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #515254;
    margin-top: 8px;
    // display: flex;
    // align-items: center;
    // flex-wrap: wrap;

    .gotIt {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      color: #7bdbc4;
      outline: none;
      background: none;
      border: none;
    }

    img.icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}
