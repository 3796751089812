.root {
    
    // max-width: 920px;
    margin: 0 auto;

    .container {
        // max-width: 820px;
        margin: 0 auto;
        background: #ffffff80 !important;
        border: none !important;
        margin-top: 20px;
    }

    :global {
        .cr-card-body {
            background: none !important;
            padding: 20px 0;
            border: none;
        }

        .cr-list + .cr-list {
            border-top: none;
        }

        .cr-page {
            background: none !important;
        }
    }
}