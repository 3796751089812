.root {
  height: calc(100% - 88px);
  position: relative;
  :global {
    .table-responsive {
      padding-top: 1rem;
    }
    .info-header {
      h4,
      p {
        font-weight: 600;
        text-align: center;
      }
    }
  }
  .container {
    position: relative;
    .wallet {
      position: absolute;
      top: -3rem;
      right: 0rem;
      height: 7.875rem;
      width: auto;
      background: #FFFFFF;
      box-shadow: 0px 1.5px 4px rgba(35, 36, 41, 0.2);
      border-radius: 20px 20px 0px 0px;
      display: grid;
      grid-template-columns: 50% 50%;
      .leftSection {
        padding-top: 10%;
        padding-left: 1rem;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #22294E;
        span {
          float: left;
          width: 100%;
        }
        button {
          max-height: 30px;
          margin-top: 1rem;
        }
      }
      .rightSection {
        padding-top: 10%;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #22294E;
        .heading {
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #9C9CA3;
          float: left;
          width: 100%;
          text-align: left;
        }
        .amount {
          float: left;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 42px;
          text-align: right;
          color: #000000;
        }
      }
    }
  }
  .tabsnav {
    padding: 0rem !important;
    border-bottom: 1px solid #E0E0E0;

    li {
      z-index: 1 !important;
    }

    .innerTab {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal !important;
      font-size: 20px !important;
      line-height: 28px !important;
      color: #22294E !important;
      background-color: transparent;
      line-height: 22px;
      color: #515254 !important;
      border-color: transparent;
      border-bottom: 2px !important;
      padding: 2rem 2rem 1rem;
      text-align: center;
      cursor: pointer;
    }

    .tabActive {
      border-bottom: 3px solid #ea734f !important;
      padding: 2rem 2rem 1rem;
      text-align: center;
      font-family: Poppins;
      font-style: normal;
      font-size: 20px !important;
      line-height: 28px;
      color: #22294e !important;
      font-weight: 600 !important;
    }
  }

  .infoHeader {
    h4,
    p {
      font-weight: 600;
      text-align: center;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;

    .title {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #22294e;
    }

    .searchBox {
      margin: 0;

      @media (max-width: 600px) {
        margin-left: auto;
        width: 50%;
        overflow: hidden;
      }
    }
  }

  .btnPay {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    letter-spacing: 0.5px;
    line-height: 19px;
    text-transform: capitalize;
    color: white;
    width: 77px;
    min-height: 30px;
    border-radius: 5px;
    padding: 0;
    background: #ea734f;
  }
  .footer {
    display: flex;
    justify-content:space-between;
    align-items: center;
    .btnAdd {

      width: 112px;
      min-height: 38px;
      padding: 0;

    }
  }
  .table {
    background-color: white;

    thead {
      color: #ea9277;
      font-weight: 600;
      font-size: 16px;
      // height: 72px;
      line-height: 22px;
      background: #fcf7f4;

      tr {
        // height: 38px;



        th {
          // padding-top: 25px;
          // padding-bottom: 25px;
          // padding-left: 25px;
          border: none;
          padding: 8px 24px;
          height: 30px;

          span {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-transform: uppercase;
          }

          .filter {
            cursor: pointer;
            color: inherit;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-decoration: none;
            text-transform: uppercase;
          }

          :global {
            .dropdown-toggle::after {
              color: #9c9ca3;
            }

            .dropdown-menu {
              box-shadow: 0px 2px 3px rgba(76, 73, 105, 0.3);
              border-radius: 5px;
              padding: 0;
              border: none;
            }

            .dropdown-item {
              font-family: Muli;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: #141414;
              cursor: pointer;
              outline: none !important;

              &:hover {
                background: none;
              }

              &.active {
                background: #ea9277 !important;
                border-radius: 5px;
                color: white;
                font-weight: 600;
              }
            }
          }
        }

        .iconDropdown {
          margin-left: 4px;
        }
      }
    }

    tbody {
      background: #fcfcfc;

      tr:nth-child(even) {
        td {
          background: white;
        }
      }

      tr.hasNew {
        td {
          background: #f8e6df;
        }
      }

      tr {
        &:hover {
          td {
            background: #f8e6df;
          }
        }

        td {
          font-family: Muli;
          font-size: 14px;
          line-height: 20px;
          // padding-left: 25px;
          vertical-align: middle;
          border: none;
          padding-left: 24px;
          height: 72px;

          &.actions {
            width: 200px;

            button {
              width: 40px !important;
              height: 40px !important;
              border-radius: 50%;
              border: none;
              background: none;
              cursor: pointer;
              outline: none !important;
              box-shadow: none !important;

              img {
                width: 20px;
                height: 20px;
              }

              &:hover {
                color: #ea734f !important;
                background-color: #faf1ef;
                opacity: 1;

                img {
                  filter: invert(62%) sepia(18%) saturate(5389%)
                    hue-rotate(328deg) brightness(97%) contrast(89%);
                }
              }
            }

            button + button {
              margin-left: 20px;
            }
          }

          &.status {
            img,
            svg {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
          }

          border-bottom: 1px solid #fcf7f4;
        }
      }
    }
  }
  .sortableHeader {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #ea9277;
  }
  .btnView {
    background: #ffffff;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 19px;
    text-transform: capitalize;
    color: #ea734f;
    width: 77px;
    min-height: 30px;
    border-radius: 5px;
    padding: 0;
    border: 1px solid #ea734f;

    &:disabled {
      background: #e0e0e0;
      color: white;
    }
  }

  .emptyState {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #515254;
    background: white !important;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none !important;

    .title {
      margin-top: 24px;
      margin-bottom: 8px;
      font-family: Poppins;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #22294e;
    }
  }

  .paging {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #515254;
    padding: 24px;
    position: relative !important;
    width: auto !important;
    bottom: 0px !important;
    div {
      padding: 0rem 1rem;
    }
  }

  .noLevel {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #9c9ca3;
  }

  .paging {
    position: absolute;
    width: 100%;
    bottom: -5px;
  }
}

.viewModalClasses {
  box-shadow: 0px 1.5px 4px rgba(35, 36, 41, 0.2);
}

.nameBlock {
  padding: 16px;
  font-family: Poppins;
  font-style: normal;
  background: #fcfcfc;
  box-shadow: 0px 1.5px 4px rgba(35, 36, 41, 0.2);
  font-weight: bold;
}

:global .paymentModal {
  max-width: 1200px !important;
}

.btnPrint {
  background: #ffffff !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 19px;
  text-transform: capitalize;
  color: #ea734f !important;
  width: auto;
  min-height: 30px;
  border-radius: 5px;
  padding: 0;

  &:disabled {
    background: #e0e0e0;
    color: white;
  }
}
.modalFooterStart {
  justify-content: flex-start;
  flex: 1;
  display: flex;
}

.classBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  p,
  span {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
    margin-bottom: 0;
  }
  .classLeftBlock {
    display: flex;
    align-items: center;
  }
  .classInfoBlock {
    display: flex;
    flex-direction: column;
  }
  .className {
    font-weight: bold !important;
  }
  .classTimings {
    font-size: 12px;
  }
  .classImage {
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 20px;
  }
}

.paymentBlock {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  margin-top: 36px;
  p {
    font-family: Poppins !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
  }
  hr {
    border-top: 1px solid rgb(0, 0, 0);
  }
  .paymentTotal {
    font-weight: bold;
  }
  .calculationBlock {
    p {
      text-align: right;
    }
  }
}

.transactionBlock {
  display: flex;
  .cardDetails {
    margin-right: 1rem;
  }
}

.modalContentWrapper {
  padding: 1rem;
}

