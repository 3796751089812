.root {
  width: 100%;
  // display: contents;
  font-family: Muli;

  .container {
    position: relative;

    > input {
      min-height: 40px;
      border: 1px solid #f8e6df;
      border-radius: 5px;
      font-size: 14px;

      &:focus {
        border: 1px solid #ea734f !important;
        outline: none;
        box-shadow: none;
      }

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #9c9ca3;
      }
    }

    textarea {
      border: 1px solid #f8e6df;
      border-radius: 5px;
      min-height: 150px;
      font-size: 14px;

      &:focus {
        border: 1px solid #ea734f !important;
        outline: none;
        box-shadow: none;
      }

      &::placeholder {
        color: #9c9ca3;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .error {
      border-color: #ea734f !important;
      background: #fcf7f4 !important;
    }

    .errorIcon {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 18px;
      height: 16px;
    }

    .errorIconDatePicker {
      position: absolute;
      top: 18px;
      right: 10px;
      width: 18px;
      height: 16px;
    }

    .errorMessage {
      align-items: center;
      display: flex;
      font-size: 12px;
      font-family: Muli;
      line-height: 16px;
      margin-top: 5px;
      color: #f55b5b;
      img {
        margin-right: 5px;
      }
    }
  }
  
}
