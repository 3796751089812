.root {
  .icon {
    width: 18px;
    height: 18px;
    margin-top: 10px !important;
  }

  .guests {
    width: 50%;

    @media (max-width: 425.98px) {
      width: 80%;
    }
  }
  .event_guests {
    width: 100%;

    @media (max-width: 425.98px) {
      width: 80%;
    }
  }

  .guestOption {
    display: flex;
    align-items: center;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #ffffff;
      border: 1px solid #22294e;
      color: #22294e !important;

      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;

      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }

    .name {
      font-family: Muli;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
    }

    .role {
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
    }
  }

  .notFound {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
        margin-right: 8px;
    }
  }
}
