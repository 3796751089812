.root {
  :global {
    .rselect__dropdown-indicator,
    .rselect__indicator-separator {
      display: none;
    }

    .rselect__control {
      border: 1px solid #f8e6df;
      box-sizing: border-box;
      border-radius: 5px;
      background: #ffffff;
      box-shadow: none;

      &:hover {
        border: 1px solid #f8e6df;
      }
      &--is-focused {
        border: 1px solid #f55b5b !important;
      }
    }

    .rselect__placeholder {
      font-weight: 600;
      font-size: 14px;
      color: #9c9ca3;
      font-family: Muli;
      line-height: 20px;
    }

    .rselect__menu,
    .rselect__value-container {
      font-size: 14px;
      color: #22294e;
      font-family: Muli;
    }

    .rselect__option {
      &:hover {
        color: #ea9277;
        background: white;
      }
    }

    .rselect__option--is-focused {
      color: #ea9277;
      background: white;
    }

    .rselect__input {
      input {
        font-size: 14px;
        color: #22294e;
        font-family: Muli;
      }
    }
  }
}
