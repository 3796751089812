.root {
  height: calc(100vh / 1.5);
  overflow-y: auto;

  .toolbarContainer {
    padding: 24px;
    display: flex;
    align-items: center;

    @media (max-width: 425.98px) {
      flex-direction: column;

      > * {
        margin-bottom: 10px;
      }
    }

    .title {
      font-family: Poppins;
      font-size: 20px;
      line-height: 28px;
      color: #22294e;
    }

    .labelDate {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #141414;
    }

    .btn {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      outline: none;
    }

    .today {
      width: 100px;
      height: 30px;
      margin-left: 20px;
      background: white;
      border: 1px solid #141414;
      border-radius: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      outline: none;
    }
  }

  .header {
    padding-top: 11px;
    padding-bottom: 11px;

    .day {
      font-family: Muli;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #515254;

      @media (max-width: 425.98px) {
        font-size: 12px;
      }
    }

    .numberDay {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
      color: #515254;

      @media (max-width: 425.98px) {
        font-size: 18px;
      }
    }
  }

  .event {
    font-family: Muli;
    height: 100%;
    border: 1px solid #a80000;
    color: #a80000;
    border-radius: 5px;
    padding: 5px;

    .studio {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .time {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      margin-bottom: 5px;
    }

    .room {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  :global {
    .rbc-current-time-indicator {
      background: none;
    }

    .rbc-time-view {
      border: none;
    }

    .rbc-allday-cell {
      display: none;
    }

    .rbc-time-header-content,
    .rbc-time-header {
      background: #fcf7f4 !important;
      border: none;
    }

    .rbc-time-header {
      filter: drop-shadow(0px 1.5px 4px rgba(35, 36, 41, 0.2));
    }

    .rbc-today {
      background: #fcf7f4 !important;

      a {
        text-decoration: none !important;
      }
    }

    .rbc-header {
      border: none !important;

      a {
        text-decoration: none !important;
      }
    }
    .rbc-header + .rbc-header {
      border: none !important;
    }

    .rbc-time-gutter {
      .rbc-time-slot {
        background: white;
        transform: translateY(-7px);
        font-family: Muli;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        padding-left: 10px;
        padding-right: 10px;
      }

      .rbc-timeslot-group:first-of-type {
        .rbc-time-slot {
          transform: translateY(0px);
        }
      }
    }

    .rbc-event-label {
      display: none;
    }

    .rbc-event {
      background: white;
      border: none;
      border-radius: 5px;
      color: #a80000;
      font-size: 12px;
      padding: 0px;
    }

    .rbc-day-slot .rbc-events-container {
      margin-right: 0px;
    }


    .rbc-calendar {
      box-sizing: border-box;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }


    .rbc-header {
      overflow: hidden;
      flex: 1 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 3px;
      text-align: center;
      vertical-align: middle;
      font-weight: bold;
      font-size: 90%;
      min-height: 0;
      border-bottom: 1px solid #DDD;
    }

    .rbc-row-content {
      position: relative;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-user-select: none;
      z-index: 4;
    }

    .rbc-today {
      background-color: #eaf6ff;
    }


    .rbc-event {
      border: none;
      box-shadow: none;
      margin: 0;
      padding: 2px 5px;
      background-color: #3174ad;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      width: 100%;
      text-align: left;
    }

    .rbc-slot-selecting .rbc-event {
      cursor: inherit;
      pointer-events: none;
    }

    .rbc-event.rbc-selected {
      background-color: #265985;
    }

    .rbc-event:focus {
      outline: 5px auto #3b99fc;
    }

    .rbc-event-label {
      font-size: 80%;
    }

    .rbc-event-overlaps {
      box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
    }

    .rbc-event-continues-prior {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .rbc-event-continues-after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .rbc-event-continues-earlier {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .rbc-event-continues-later {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .rbc-row {
      display: flex;
      flex-direction: row;
    }

    .rbc-row-segment {
      padding: 0 1px 1px 1px;
    }

    .rbc-selected-cell {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .rbc-date-cell {
      flex: 1 1;
      min-width: 0;
      padding-right: 5px;
      text-align: right;
    }

    .rbc-date-cell.rbc-now {
      font-weight: bold;
    }

    .rbc-date-cell>a,
    .rbc-date-cell>a:active,
    .rbc-date-cell>a:visited {
      color: inherit;
      text-decoration: none;
    }

    .rbc-row-bg {
      display: flex;
      flex-direction: row;
      flex: 1 0;
      overflow: hidden;
    }

    .rbc-day-bg {
      flex: 1 0;
    }

    .rbc-day-bg+.rbc-day-bg {
      border-left: 1px solid #DDD;
    }

    .rbc-rtl .rbc-day-bg+.rbc-day-bg {
      border-left-width: 0;
      border-right: 1px solid #DDD;
    }

    .rbc-overlay {
      position: absolute;
      z-index: 5;
      border: 1px solid #e5e5e5;
      background-color: #fff;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
      padding: 10px;
    }

    .rbc-overlay>*+* {
      margin-top: 1px;
    }

    .rbc-overlay-header {
      border-bottom: 1px solid #e5e5e5;
      margin: -10px -10px 5px -10px;
      padding: 2px 10px;
    }

    .rbc-time-column {
      display: flex;
      flex-direction: column;
      min-height: 100%;
    }

    .rbc-time-column .rbc-timeslot-group {
      flex: 1 1;
    }

    .rbc-timeslot-group {
      border-bottom: 1px solid #DDD;
      min-height: 40px;
      display: flex;
      flex-flow: column nowrap;
    }

    .rbc-time-gutter,
    .rbc-header-gutter {
      flex: none;
    }

    .rbc-label {
      padding: 0 5px;
    }

    .rbc-day-slot {
      position: relative;
    }

    .rbc-day-slot .rbc-events-container {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      margin-right: 10px;
      top: 0;
    }

    .rbc-day-slot .rbc-events-container.rbc-is-rtl {
      left: 10px;
      right: 0;
    }

    .rbc-day-slot .rbc-event {
      border: 1px solid #265985;
      display: flex;
      max-height: 100%;
      min-height: 20px;
      flex-flow: column wrap;
      align-items: flex-start;
      overflow: hidden;
      position: absolute;
    }

    .rbc-day-slot .rbc-event-label {
      flex: none;
      padding-right: 5px;
      width: auto;
    }

    .rbc-day-slot .rbc-event-content {
      width: 100%;
      flex: 1 1;
      word-wrap: break-word;
      line-height: 1;
      height: 100%;
      min-height: 1em;
    }

    .rbc-day-slot .rbc-time-slot {
      border-top: 1px solid #f7f7f7;
    }

    .rbc-time-view-resources .rbc-time-gutter,
    .rbc-time-view-resources .rbc-time-header-gutter {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      background-color: white;
      border-right: 1px solid #DDD;
      z-index: 10;
      margin-right: -1px;
    }

    .rbc-time-view-resources .rbc-time-header {
      overflow: hidden;
    }

    .rbc-time-view-resources .rbc-time-header-content {
      min-width: auto;
      flex: 1 0;
      flex-basis: 0px;
    }

    .rbc-time-view-resources .rbc-time-header-cell-single-day {
      display: none;
    }

    .rbc-time-view-resources .rbc-day-slot {
      min-width: 140px;
    }

    .rbc-time-view-resources .rbc-header,
    .rbc-time-view-resources .rbc-day-bg {
      width: 140px;
      flex: 1 1;
      flex-basis: 0px;
    }

    .rbc-time-header-content+.rbc-time-header-content {
      margin-left: -1px;
    }

    .rbc-time-slot {
      flex: 1 0;
    }

    .rbc-time-slot.rbc-now {
      font-weight: bold;
    }

    .rbc-day-header {
      text-align: center;
    }

    .rbc-slot-selection {
      z-index: 10;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 75%;
      width: 100%;
      padding: 3px;
    }

    .rbc-slot-selecting {
      cursor: move;
    }

    .rbc-time-view {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      width: 100%;
      border: 1px solid #DDD;
      min-height: 0;
    }

    .rbc-time-view .rbc-time-gutter {
      white-space: nowrap;
    }

    .rbc-time-view .rbc-allday-cell {
      box-sizing: content-box;
      width: 100%;
      height: 100%;
      position: relative;
    }

    .rbc-time-view .rbc-allday-cell+.rbc-allday-cell {
      border-left: 1px solid #DDD;
    }

    .rbc-time-view .rbc-allday-events {
      position: relative;
      z-index: 4;
    }

    .rbc-time-view .rbc-row {
      box-sizing: border-box;
      min-height: 20px;
    }

    .rbc-time-header {
      display: flex;
      flex: 0 0 auto;
      flex-direction: row;
    }

    .rbc-time-header.rbc-overflowing {
      border-right: 1px solid #DDD;
    }

    .rbc-rtl .rbc-time-header.rbc-overflowing {
      border-right-width: 0;
      border-left: 1px solid #DDD;
    }

    .rbc-time-header>.rbc-row:first-child {
      border-bottom: 1px solid #DDD;
    }

    .rbc-time-header>.rbc-row.rbc-row-resource {
      border-bottom: 1px solid #DDD;
    }

    .rbc-time-header-cell-single-day {
      display: none;
    }

    .rbc-time-header-content {
      flex: 1 1;
      display: flex;
      min-width: 0;
      flex-direction: column;
      border-left: 1px solid #DDD;
    }

    .rbc-rtl .rbc-time-header-content {
      border-left-width: 0;
      border-right: 1px solid #DDD;
    }

    .rbc-time-content {
      display: flex;
      flex: 1 0;
      align-items: flex-start;
      width: 100%;
      border-top: 2px solid #DDD;
      overflow-y: auto;
      position: relative;
    }

    .rbc-time-content>.rbc-time-gutter {
      flex: none;
    }

    .rbc-time-content>*+*>* {
      border-left: 1px solid #DDD;
    }

    .rbc-rtl .rbc-time-content>*+*>* {
      border-left-width: 0;
      border-right: 1px solid #DDD;
    }

    .rbc-time-content>.rbc-day-slot {
      width: 100%;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-user-select: none;
    }

    .rbc-current-time-indicator {
      position: absolute;
      z-index: 3;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #74ad31;
      pointer-events: none;
    }

  }
}
