.root {
  .hasError {
    input {
      border-color: #f55b5b !important;
      background: #fcf7f4 !important;
    }
  }

  :global {
    .react-datepicker-ignore-onclickoutside {
      border-color: #f55b5b !important;
    }

    .react-datepicker__input-container {
      input {
        width: 85px;
        height: 40px;
        border: 1px solid #f8e6df;
        box-sizing: border-box;
        border-radius: 5px;
        background: #ffffff;
        box-shadow: none;
        outline: none;
        padding: 5px 10px;
        font-size: 14px;
        font-family: Muli;
      }
    }

    .react-datepicker__time-container {
      width: 85px;
    }

    .react-datepicker__time-list-item {
      font-weight: normal;
    }

    .react-datepicker__time {
      background: none;
    }

    .react-datepicker {
      width: 85px;
      border: 1px solid #f8e6df;
      box-sizing: border-box;
      border-radius: 5px;
      background: #ffffff;
      box-shadow: none;
      font-family: Muli;
      font-size: 14px;
    }    

    .react-datepicker__header--time,
    .react-datepicker__triangle {
      display: none;
    }

    li.react-datepicker__time-list-item--selected {
      background: #ea734f !important;
      color: white !important;
      border-radius: 5px;
      margin-left: 4px;
      margin-right: 4px;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item:hover {
      background: none;
    }

    .react-datepicker-popper[data-placement^='bottom'] {
      margin-top: 1px;
      z-index: 10;
    }
  }
}

:global {
  .react-datepicker__time-list {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .react-datepicker__time-list::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
