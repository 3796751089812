.root {
  position: relative;
  

  input {
    border: 1px solid #f8e6df;
    box-sizing: border-box;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: none !important;
    outline: none !important;
    padding-right: 10px;
    padding-left: 7px;
    height: 40px;
    &:focus {
      border-color: #ea734f;
    }
  }

  .error {
    border-color: #ea734f !important;
    background: #FCF7F4 !important;
  }

  button {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    box-shadow: none !important;
    outline: none !important;

    &.up {
      width: 6px;
      height: 4px;
      right: 5px;
      top: 12px;
      border: none;
      background: none;

      img {
        position: absolute;
        width: 6px;
        height: 4px;
      }
    }

    &.down {
      width: 6px;
      height: 4px;
      right: 5px;
      bottom: 16px;
      border: none;
      background: none;

      img {
        position: absolute;
        width: 6px;
        height: 4px;
      }
    }
  }

  .disabled {
    opacity: 0.4;
  }
}
